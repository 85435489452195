
import { ISimpleDataItem } from '@/modules/system/types';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import $app from '@/plugins/modules'

@Component
export default class ModalListEdit extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => false })
  readonly readonly: any;

  @Prop({ default: () => [] })
  readonly item: ISimpleDataItem | undefined;

  @Prop({ default: () => '' })
  readonly caption: string | undefined;

  // Edit view modes:
  // - name-only
  // - name-and-id
  // - name-and-code
  // - name-and-descr
  // - full
  @Prop({ default: () => '' })
  readonly mode: string | undefined;

  data: ISimpleDataItem | null = null;

  @Watch('value')
  onShow() {
    if (this.value) {
      this.data = $app.clone(this.item);
    }
  }

  get isEditable() {
    return !this.readonly && this.readonly !== '';
  }

  get editMode(): string {
    return this.mode || 'name-and-id';
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    this.$emit('store', this.data)
  }
}
